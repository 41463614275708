import React from "react";
import { graphql } from "gatsby";

import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta";
import BlockZone from "~blockZone";
import Layout from "@components/layout";

const PageTemplate = ({ data: { page } }) => {
  const { title, meta, pageBlocks } = page || {};
  return (
    <Layout bg={meta?.pageColor?.value}>
      <h1>{title}</h1>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
        pageColor {
          title
          value
        }
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
